import { AxiosError } from "axios";
import { Form, Formik } from "formik";
import { useCallback, useEffect, useState } from "react";
import { useQueryClient } from "react-query";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import axiosInstance from "../../axiosInstance";
import { hideModal } from "../../redux/features/modalSlice";
import { addToast } from "../../redux/features/toastSlice";
import { ERROR, SUCCESS } from "../../types/constants";
import Input from "../FormikComponents/Input";
import Select from "../FormikComponents/Select";
import SubmitBtn from "../FormikComponents/SubmitBtn";
import CenterMarker from "../MapsComponents/CenterMarker";

interface VetObj {
  name: string;
  phone: string;
  email: string;
  password: string;
  cpassword?: string;
  vet_bio: string;
  has_clinic: string;
  profile_picture_url?: string;
  experience?: number;
  specialization_id?: number;
  address?: {
    address_line: string;
    label: string;
    city: string;
    state: string;
    pincode: string;
    lat: number;
    lng: number;
  };
}

const AddVetModal = () => {

  const [role, setRole] = useState(localStorage.getItem('role'))

  function isAxiosError(error: any): error is AxiosError {
    return error.isAxiosError === true;
  }

  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [profilePicture, setProfilePicture] = useState<File | null>(null);
  const [specialisations, setSpecialisations] = useState<{ value: number; label: string }[]>([]);

  const clinicOptions = [
    { value: "true", label: "Yes" },
    { value: "false", label: "No" },
  ];

  const initialValues: VetObj = {
    name: "",
    phone: "",
    email: "",
    password: "",
    cpassword: "",
    vet_bio: "",
    has_clinic: "No",
    experience: 0,
    specialization_id: undefined,
    address: {
      address_line: "",
      label: "clinic",
      city: "",
      state: "",
      pincode: "",
      lat: 0.0,
      lng: 0.0,
    },
  };


  const validationSchema = Yup.object({
    name: Yup.string().required("Name is required").min(3, "Name must be at least 3 characters"),
    phone: Yup.string().required("Phone number is required").min(10, "Phone number must be 10 digits").max(10, "Phone number must be 10 digits"),
    email: Yup.string().email("Invalid email").required("Email is required"),
    password: Yup.string().required("Password is required").min(8, "Password must be at least 8 characters"),
    cpassword: Yup.string().oneOf([Yup.ref("password"), null], "Passwords must match"),
    vet_bio: Yup.string().required("Bio is required").min(10, "Bio must be at least 10 characters"),
    has_clinic: Yup.boolean().required("Please specify if the vet has a clinic"),
    experience: Yup.number().required("Experience is required"),
    specialization_id: Yup.number().required("Specialization is required"),
    address: Yup.object().when("has_clinic", {
      is: true,
      then: Yup.object().shape({
        address_line: Yup.string().required("Address line is required"),
        label: Yup.string().required("Address label is required"),
        city: Yup.string().required("City is required"),
        state: Yup.string().required("State is required"),
        pincode: Yup.string().required("Pincode is required"),
        lat: Yup.number().required("Latitude is required"),
        lng: Yup.number().required("Longitude is required"),
      }),
      otherwise: Yup.object().notRequired(),
    }),
  });

  useEffect(() => {
    // Fetch specialization data when the component mounts
    const fetchSpecialisations = async () => {
      try {
        const response = await axiosInstance.get(`/other/specialization/all`);
        const { data } = response.data;
        const formattedSpecialisations = data.map((specialization: { specialization_id: number; name: string }) => ({
          value: specialization.specialization_id,
          label: specialization.name,
        }));
        setSpecialisations(formattedSpecialisations);
      } catch (error) {
        dispatch(addToast({ kind: ERROR, msg: "Failed to fetch specialisations" }));
      }
    };

    fetchSpecialisations();
  }, [dispatch]);

  const uploadProfilePicture = async (file: File, vetName: string) => {
    const formData = new FormData();
    formData.append("image", file);

    const response = await axiosInstance.post(`/admin/image/upload/${vetName}`, formData);
    return response.data.data.publicUrl;
  };

  const handleSubmit = useCallback(async (vet: VetObj) => {
    setIsSubmitting(true);

    try {
      let profilePictureUrl;
      if (profilePicture) {
        // profilePictureUrl = await uploadProfilePicture(profilePicture, vet.name);
      }

      if (vet.password !== vet.cpassword) {
        dispatch(addToast({ kind: ERROR, msg: "Passwords do not match" }));
        setIsSubmitting(false);
        return;
      }

      delete vet.cpassword;

      await axiosInstance.post(
        role === 'admin' ? `/admin/veterinary/create` : `/veterinary/register`,
        {
          ...vet,
          phone: String(vet.phone),
          has_clinic: vet.has_clinic === "true",
          profile_picture_url: profilePictureUrl,
          address: vet.has_clinic === "true" ? vet.address : undefined,
        },
        {
          headers: {
            ContentType: "application/json",
          },
        }
      );

      queryClient.invalidateQueries(["getAllVets"]);
      dispatch(addToast({ kind: SUCCESS, msg: "Veterinary added successfully" }));
      dispatch(hideModal());
    } catch (error) {
      if ((isAxiosError(error) && error.response)) {
        const { msg } = error.response.data;
        dispatch(addToast({ kind: ERROR, msg }));
      } else {
        dispatch(addToast({ kind: ERROR, msg: "Oops, something went wrong" }));
      }
    } finally {
      setIsSubmitting(false);
    }
  }, [profilePicture]);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values) => handleSubmit(values)}
    >
      {({ values, setFieldValue }) => (
        <Form className="px-6 py-4 mt-2" style={{ minWidth: "360px", maxWidth: "760px" }}>
          <div className="flex item-center gap-x-6">

            <Input label="Name" id="name" name="name" type="text" placeholder="Name" />
            <Input label="Phone" id="phone" name="phone" type="number" placeholder="Phone" />
          </div>
          <Input label="Email" id="email" name="email" type="email" placeholder="example@gmail.com" />
          <Input label="Password" id="password" name="password" type="password" placeholder="Enter Password" />
          <Input label="Confirm Password" id="cpassword" name="cpassword" type="password" placeholder="Confirm Password" />

          <Input label="Bio" id="vet_bio" name="vet_bio" type="text" placeholder="Bio" />
          <Select label="Has Clinic?" id="has_clinic" name="has_clinic" options={clinicOptions} />
          <Input label="Experience (in months)" id="experience" name="experience" type="number" placeholder="Enter Experience in months" />
          <Select
            label="Specialization"
            id="specialization_id"
            name="specialization_id"
            options={specialisations}
          />

          {role === 'admin' && (
            <>
              <p className="font-semibold mb-2 text-sm">Vet Profile Picture</p>
              <input
                type="file"
                accept="image/*"
                onChange={(e) => setProfilePicture(e.target.files ? e.target.files[0] : null)}
              />
            </>
          )}


          {values.has_clinic === "true" && (
            <>
              <h3 className="text-lg font-semibold mt-4">Address</h3>
              <Input label="Address Line" id="address.address_line" name="address.address_line" type="text" placeholder="eg. Street No. XXX, ..." />
              <Input label="Label" id="address.label" name="address.label" type="text" placeholder="Enter Label" />
              <Input label="City" id="address.city" name="address.city" type="text" placeholder="eg. jaipur" />
              <Input label="State" id="address.state" name="address.state" type="text" placeholder="eg. Delhi" />
              <Input label="Pincode" id="address.pincode" name="address.pincode" type="text" placeholder="eg. 110201" />

              <CenterMarker latName="address.lat" lngName="address.lng" />
              {/* <Input label="Latitude" id="address.lat" name="address.lat" type="number" />
              <Input label="Longitude" id="address.lng" name="address.lng" type="number" /> */}
            </>
          )}

          <div className="buttons flex items-center w-full justify-center my-4">
            <SubmitBtn text="Save" isSubmitting={isSubmitting} classes="text-sm" />
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default AddVetModal;
